// config.js

const bizIcon = 'https://jjelectricmotorsny.com/wp-content/uploads/2020/05/electrical-motor-png-logo-header.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://jjelectricmotorsny.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-25-at-3.18.33-PM-1.jpeg";
const imageTwo = "https://jjelectricmotorsny.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-25-at-3.18.33-PM-2.jpeg";
const imageThree = "https://jjelectricmotorsny.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-25-at-3.18.33-PM-4.jpeg";
const imageFour = "https://jjelectricmotorsny.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-25-at-3.18.33-PM-3.jpeg";
const imageFive = "https://jjelectricmotorsny.com/wp-content/uploads/2024/10/form-call-to-action-1024x555.jpg";

const redirectLink = "https://jjelectricmotorsny.com/";

export const siteConfig = {
  title: "JJ Electric Motors",
  metaDescription: "E&C Remodeling LLC",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "E&C Remodeling LLC Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "JJ Electric Motors",
    headline: "Precision Electric Motor Installations Backed by 25+ Years of Expertise",
    address: "New York, NY, USA",
    description: `
        JJ Electric Motors specializes in comprehensive electric motor installation services tailored to meet the diverse needs of our clients. With over 25 years of experience, our expert team ensures precision, professionalism, and top-tier customer satisfaction in every project. From residential to industrial installations, JJ Electric Motors is your trusted partner in achieving seamless and efficient motor solutions.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Excellence in Roofing, Demolition, and Siding",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Efficient & Reliable Performance: Proper installation is crucial for the efficiency and longevity of your electric motors. At JJ Electric Motors, we ensure that every installation is performed to maximize performance and reliability.",
    },
    {
        description: 
          "Site Assessment: Our team conducts a thorough assessment of your site to determine the best approach for motor installation, ensuring a seamless and efficient setup.",
    },
    {
        description: 
          "Precision Installation and Calibration: We meticulously align and secure all motor components during installation. Rigorous testing and calibration follow to confirm that your motors are operating at peak efficiency.",
    },
  ],
  services: {
    sectionTitle: "Our Services",
    description: 
      "JJ Electric Motors specializes in electric motor installations, electric generator setups, and water pump integrations. Serving both residential and commercial clients in New York, we ensure precision, efficiency, and satisfaction in every project.",
    callouts: [
      {
        name: 'Electric Generators',
        description: 
          "Our expert team provides tailored installation services for electric generators, ensuring reliable power solutions for homes and businesses.",
        imageSrc: imageTwo,
        imageAlt: 'JJ Electric Motors team installing an electric generator in New York.',
      },
      {
        name: 'Water Pumps',
        description: 
          "Professional installation of water pumps designed to improve the efficiency and reliability of your water supply systems.",
        imageSrc: imageThree,
        imageAlt: 'JJ Electric Motors working on a water pump installation in New York.',
      },
      {
        name: 'Electric Installation',
        description: 
          "Comprehensive electric motor installation services include site assessment, precise alignment, and thorough calibration for optimal performance.",
        imageSrc: imageFour,
        imageAlt: 'JJ Electric Motors team performing an electric motor installation in New York.',
      },
    ],
  },
  about: {
    sectionTitle: "About",
    description:   
      "JJ Electric Motors is your trusted partner for comprehensive electric motor solutions in New York. With over 25 years of industry experience, we specialize in electric motor installations, electric generators, and water pump systems for both residential and commercial clients. Our fully licensed and insured team employs advanced tools, high-quality materials, and meticulous precision to deliver exceptional results. At JJ Electric Motors, we pride ourselves on reliability, innovation, and a customer-focused approach. Contact us today to experience professional service where your needs drive our solutions.",
    image: {
      src: imageFive,
      alt: "JJ Electric Motors team providing high-quality electric motor installation services in New York.",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "New York, USA",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24136.062612564765!2d-74.0059723!3d40.712776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a31600b01d7%3A0xabcdef123456789!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=New%20York,%20USA&ll=40.712776,-74.005974&q=New%20York',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=New+York,+USA&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/New+York,+USA/@40.712776,-74.005974,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/New+York,+USA/@40.712776,-74.005974,15z',
      }
    ]
  },
  contact: {
    sectionTitle: "Get in Touch",
    description: 
      "At JJ Electric Motors, we're here to help with all your electric motor installation needs. Whether you have questions, need a quote, or require expert advice, our team is ready to assist. Contact us today to discuss your project and experience professional service you can rely on.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (347) 558-1603",
    ],
    logo: bizIcon,
    emailRecipient: "jjmotoresindustriales@gmail.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


